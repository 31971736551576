import './../css/Modals.css'
import Spinner from 'react-bootstrap/Spinner';

export default function Loading() {
  return (
    <div className='modals-backdrop'>
        <div className="modals">
          <Spinner className="mt-4 p-5" animation="border" variant="light"/>
        </div>
    </div>
  )
}
