import { GlobalContext } from "../contexts/GlobalContext"
import { useContext } from "react"

export const useGlobalContext = () => {
    const context = useContext(GlobalContext)

    if(!context) {
        throw Error('useGlobalContext must be inside an AuthContextProvider')
    }

    return context
}