// css and bootstrap imports
import { Button } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { ArrowLeftCircle, ArrowRightCircle } from "react-bootstrap-icons";
import Image from "react-bootstrap/Image";
import '../css/Fonts.css'

// component imports
import AddJob from "../forms/AddJob";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// hooks imports
import { useCollection } from "../hooks/useCollection";
import { useImage } from "../hooks/useImage";
import { useGlobalContext } from "../hooks/useGlobalContext";

// react imports
import { useEffect } from "react";

// firebase imports
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase/config";

// aos
import Aos from "aos";
import "aos/dist/aos.css";

const JobList = ({ jobs }) => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const { user, color } = useGlobalContext();
  const { imageList: logoList } = useImage('logos');

  const deleteJob = (id) => {
    deleteDoc(doc(db, "jobs", id));
  };

  return (
    <div className="job-list">
      <Container>
        <Row className="xsm-none " data-aos="flip-right" data-aos-duration="3000">
          {jobs &&
            jobs.map((job) => (
              <Col className="mb-5" md={6} lg={6} xl={4} key={job.id}>
                <Card className={`${color} mx-2 h-100`}>
                  <LazyLoadImage
                    className="card-img-top"
                    variant="top"
                    src={job.companyLogoURL}
                  />
                  <Card.Header className="pt-4">
                    <h3 style={{ fontSize: 28 }} className="black-ts">
                      {job.employer}
                    </h3>
                    <h4 style={{ fontSize: 22 }} className="gemunu mb-0 black-ts">
                      {job.position}
                    </h4>
                    <p style={{ fontSize: 20 }} className="my-1 fs-5 black-ts">
                      {job.start.replace(/(\d{4})\-(\d{2}).*/, "$2-$1")} -{" "}
                      {job.current
                        ? "Current"
                        : job.end.replace(/(\d{4})\-(\d{2}).*/, "$2-$1")}
                    </p>
                  </Card.Header>
                  <Card.Body>
                    <ul>
                      {job.description
                        .split(".")
                        .filter((element) => element)
                        .map((x) => (
                          <li
                            key={Math.random()}
                            style={{ listStyleType: "circle", fontSize: 18}}
                            className="text-start mb-2 gemunu black-ts"
                          >
                            {x}
                          </li>
                        ))}
                    </ul>
                    <div className="d-flex justify-content-center">
                      {job.stack &&
                        job.stack.split(" ").map((x) => {
                          return logoList.map((y) => {
                            if (x.toLowerCase() + ".png" === y.name)
                              return (
                                <img
                                  key={Math.random()}
                                  width="45"
                                  height="45"
                                  className={`m-2 rounded-circle ${color}-skills-logo`}
                                  src={y.imageURL}
                                  alt="logo"
                                />
                              );
                          });
                        })}
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    <a href={job.companyURL} target="_blank" rel="noreferrer">
                      <Button className={`my-3 ${color}-btn`}>Website Link</Button>
                    </a>

                    {user && <AddJob data={job} />}
                    {/* {user && (
                      <Button
                        className="my-3 ms-4 orange-btn"
                        onClick={() => deleteJob(job.id)}
                      >
                        Delete
                      </Button>
                    )} */}

                    {/* {job.projectURL && (
                    <a href={job.projectURL} target="_blank" rel="noreferrer">
                      <Button className="my-3 ms-4 orange-btn">
                        Project Link
                      </Button>
                    </a>
                  )} */}
                  </Card.Footer>
                </Card>
              </Col>
            ))}
        </Row>

        <Carousel
          className="xsm-block mb-3 px-2"
          interval={null}
          fade
          indicators={false}
          data-aos="flip-right" data-aos-duration="3000"
        >
          {jobs.map((job) => (
            <Carousel.Item key={job.id} className="h-100">
              <Card className={`${color} m-2`}>
                <Image
                  className="card-img-top"
                  variant="top"
                  src={job.companyLogoURL}
                />
                <Card.Header className="pt-4">
                  <h3 style={{ fontSize: 28 }} className="black-ts">
                    {job.employer}
                  </h3>
                  <h4 style={{ fontSize: 20 }} className="gemunu mb-0 black-ts">
                    {job.position}
                  </h4>
                  <div className="d-flex justify-content-between">
                    <ArrowLeftCircle className="my-auto" size={20} />
                    <p style={{ fontSize: 20 }} className="my-1 fs-5 black-ts">
                      {job.start.replace(/(\d{4})\-(\d{2}).*/, "$2-$1")} -{" "}
                      {job.current
                        ? "Current"
                        : job.end.replace(/(\d{4})\-(\d{2}).*/, "$2-$1")}
                    </p>
                    <ArrowRightCircle className="my-auto" size={20} />
                  </div>
                </Card.Header>
                <Card.Body>
                  <ul>
                    {job.description
                      .split(".")
                      .filter((element) => element)
                      .map((x) => (
                        <li
                          key={Math.random()}
                          style={{ listStyleType: "circle"}}
                          className="text-start mb-2 gemunu black-ts"
                        >
                          <p>{x}</p>
                        </li>
                      ))}
                  </ul>
                  <div className="d-flex justify-content-center">
                    {job.stack &&
                      job.stack.split(" ").map((x) => {
                        return logoList.map((y) => {
                          if (x.toLowerCase() + ".png" === y.name)
                            return (
                              <img
                                key={Math.random()}
                                width="45"
                                height="45"
                                className={`m-2 rounded-circle ${color}-skills-logo`}
                                src={y.imageURL}
                                alt="logo"
                              />
                            );
                        });
                      })}
                  </div>
                </Card.Body>
                <Card.Footer>
                  <a href={job.companyURL} target="_blank" rel="noreferrer">
                    <Button className={`my-3 ${color}-btn`}>Website Link</Button>
                  </a>

                  {user && <AddJob data={job} />}
                  {/* {user && (
                    <Button
                      className="my-3 ms-4 orange-btn"
                      onClick={() => deleteJob(job.id)}
                    >
                      Delete
                    </Button>
                  )} */}

                  {/* {job.projectURL && (
                    <a href={job.projectURL} target="_blank" rel="noreferrer">
                      <Button className="my-3 ms-4 orange-btn">
                        Project Link
                      </Button>
                    </a>
                  )} */}
                </Card.Footer>
              </Card>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

export default JobList;
