import { useState } from "react";

// css & bootsrap imports
import "./css/Victory.css";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { Image } from "react-bootstrap";
import { Button } from "react-bootstrap";

// hook imports
import { useGlobalContext } from "../../hooks/useGlobalContext";

const Scoreboard = ({ shuffleCards, matcherUserData }) => {
  const [scoreboardShow, setScoreboardShow] = useState(false);
  const { color } = useGlobalContext();
  return (
    <>
      <Button
        className={`${color}-btn ms-2`}
        onClick={() => {
          setScoreboardShow(true);
        }}
      >
        Scoreboard
      </Button>

      <Modal
        backdrop="static"
        size="md"
        show={scoreboardShow}
        onHide={() => setScoreboardShow(false)}
        aria-labelledby="example-modal-sizes-title-md"
        centered
        className="zoom-modal p-0"
        contentClassName={`${color}`}
      >
        <Modal.Header className="border-0 p-4" closeButton>
          <Modal.Title className="koulen" id="example-modal-sizes-title-md">
            <h2 className="mb-0">Top 5 Scores</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="koulen text-center">
          <Table bordered responsive>
            <thead>
              <tr className="gemunu text-white align-middle">
                <th>#</th>
                <th>Name</th>
                <th>Turns</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {matcherUserData &&
                matcherUserData.map((user, index) =>
                (index < 5) ?
                 (
                  <tr key={user.id} className="gemunu text-white align-middle">
                    <td >{index + 1}</td>
                    <td>{user.userName}</td>
                    <td>{user.turns}</td>
                    <td>{Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'long',day: '2-digit'}).format(user.timeStamp)}
                      {/* {Date(user.timeStamp)
                        .toString()
                        .substr(4, 11)
                        .split(" ")
                        .join("-")} */}
                    </td>
                  </tr>
                ) : '')}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Scoreboard;
