// css and bootstrap imports
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { ArrowLeftCircle, ArrowRightCircle } from "react-bootstrap-icons";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import AddCert from "../forms/AddCert";
import "../css/Fonts.css";
import "react-lazy-load-image-component/src/effects/blur.css";

// component imports
import { LazyLoadImage } from "react-lazy-load-image-component";
import Matcher from "../projects/matcher/Matcher";
import Loading from "./Loading";

// react imports
import { useEffect, useState } from "react";

// firebase imports
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase/config";

// hook imports
import { useGlobalContext } from "../hooks/useGlobalContext";

// aos
import Aos from "aos";
import "aos/dist/aos.css";

// assets

import space from "../projects/matcher/images/space_matcher.png";

const Projects = () => {
  const { color } = useGlobalContext();
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 1000 });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="projects">
      <Container>
        <h2 className={`my-5 ${color}-border`}>Projects</h2>
        <span className={`${color}-bar-1 my-5`}>'</span>
        <Row
          className="mb-5 justify-content-center px-2 px-lg-0 px-xl-0"
          data-aos="fade-up"
        >
          <Col md={6} lg={6} xl={4}>
            <Card className={`${color} mx-2 h-100`}>
              <Image
                fluid
                className="card-img-top"
                variant="top"
                src={space}
                onLoad={() => {
                  setImageLoaded(true);
                }}
              />
              <Card.Header className="py-4">
                <h3 style={{ fontSize: 28 }} className="black-ts">
                  Space Matcher 5000
                </h3>
                <h4
                  style={{ fontSize: 22 }}
                  className="gemunu mb-0 black-ts xsm-none"
                >
                  Memory Matching Game
                </h4>
                <h4
                  style={{ fontSize: 20 }}
                  className="gemunu mb-0 black-ts xsm-block"
                >
                  Memory Matching Game
                </h4>
              </Card.Header>
              <Card.Body>
                <ul className="mb-0 xsm-none">
                  <li
                    style={{ listStyleType: "circle", fontSize: 18 }}
                    className="text-start mb-2 gemunu black-ts"
                  >
                    The goal of the game is to match 3 of the same images
                    together until all images are matched
                  </li>
                  <li
                    style={{ listStyleType: "circle", fontSize: 18 }}
                    className="text-start mb-2 gemunu black-ts"
                  >
                    The game was built using react and basic css animations
                  </li>
                </ul>
                <ul className="mb-0 xsm-block">
                  <li
                    style={{ listStyleType: "circle" }}
                    className="text-start mb-2 gemunu black-ts"
                  >
                    The goal of the game is to match 3 of the same images
                    together until all images are matched
                  </li>
                  <li
                    style={{ listStyleType: "circle" }}
                    className="text-start mb-2 gemunu black-ts"
                  >
                    The game was built using react and basic css animations
                  </li>
                </ul>
              </Card.Body>
              <Card.Footer>
                <Matcher />
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        {!imageLoaded && <Loading />}
      </Container>
    </div>
  );
};

export default Projects;
