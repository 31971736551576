// react imports
import React from "react";
import { useState, useEffect, useRef } from "react";

// package imports
import emailjs from "emailjs-com";

// css & boostrap imports
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { Button, Spinner } from "react-bootstrap";
import "./../css/Buttons.css";
import "./../css/Modals.css";

// firebase imports
import { db, storage } from "../firebase/config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";

// hooks
import { useGlobalContext } from "../hooks/useGlobalContext";

function MyVerticallyCenteredModal(props) {
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submit, setSubmit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const {color} = useGlobalContext();

  useEffect(() => {
    setFirst("");
    setLast("");
    setEmail("");
    setMessage("");
    setSubmit(false);
    setSuccess(false);
    setError(false);
  }, [props.show]);

  async function handleSubmit(e) {
    e.preventDefault();

    setSubmit(true);

    emailjs
      .sendForm(
        "service_e37636g",
        "template_y1ou8vp",
        e.target,
        "eW0kXGKfmurR-yQW_"
      )
      .then(
        (result) => {
          setSuccess(true);
          setSubmit(false);
          setError(false);
          setFirst("");
          setLast("");
          setEmail("");
          setMessage("");
          setTimeout(() => {
            props.onHide();
          }, 2000);
        },
        (error) => {
          setError(true);
          setSubmit(false);
          console.log(error.text);
        }
      );
  }

  return (
    <Modal
      {...props}
      backdrop="static"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName={`${color}`}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="koulen">
          Contact Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mx-3">
        <Form id="jobForm" onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="first">
            <Form.Label className="koulen">First Name</Form.Label>
            <Form.Control
              name="first"
              value={first}
              required
              type="text"
              placeholder="First Name"
              onChange={(e) => setFirst(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="last">
            <Form.Label className="koulen">Last Name</Form.Label>
            <Form.Control
              name="last"
              value={last}
              required
              type="text"
              placeholder="Last Name"
              onChange={(e) => setLast(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="email">
            <Form.Label className="koulen">Email</Form.Label>
            <Form.Control
              name="email"
              value={email}
              required
              type="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="message">
            <Form.Label className="koulen">Message</Form.Label>
            <Form.Control
              name="message"
              as="textarea"
              rows={3}
              value={message}
              required
              className="form-control"
              placeholder="Message"
              onChange={(e) => setMessage(e.target.value)}
            />
          </Form.Group>

          <div className="text-center">
            {success && (
              <Alert.Heading className="mb-3 fs-6 koulen">
                Email Sent Successfully!
              </Alert.Heading>
            )}

            {error && (
              <Alert.Heading className="mb-3 fs-6 koulen">
                Error Alert! Please Check Console!
              </Alert.Heading>
            )}
            <Button className={`${color}-btn`} type="submit">
              {submit && (
                <Spinner
                  className="me-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

const ContactForm = () => {
  const [modalShow, setModalShow] = React.useState(false);

  const {color} = useGlobalContext();

  return (
    <>
      <div>
        <Button
          data-aos="fade-up"
          className={`mt-3 ${color}-btn`}
          onClick={() => setModalShow(true)}
        >
          Open Form
        </Button>
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default ContactForm;
