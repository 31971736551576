// css & bootstrap imports
import "./css/Footer.css";
import { Linkedin } from "react-bootstrap-icons";
import { EnvelopeOpenFill } from "react-bootstrap-icons";
import { Github } from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

// hooks

import { useGlobalContext } from "../hooks/useGlobalContext";

const Footer = () => {

  const {color} = useGlobalContext()

  return (
    <div className={`footer ${color} d-flex flex-column justify-content-evenly`}>
      <div className={`navbar-nav ${color} flex-row justify-content-center`}>
        <NavLink
          className={`fs-5 ${color}-ts-2 text-center text-white text-decoration-none p-2`}
          to="/"
        >
          About
        </NavLink>
        <NavLink
          className={`fs-5 ${color}-ts-2 text-center text-white text-decoration-none p-2`}
          to="/resume"
        >
          Resume
        </NavLink>
        <NavLink
          className={`fs-5 ${color}-ts-2 text-center text-white text-decoration-none p-2`}
          to="/projects"
        >
          Projects
        </NavLink>
        {/* <NavLink
              className="fs-5 footer-menu text-white text-decoration-none p-2"
              to="/projects"
            >
              Projects
            </NavLink> */}
      </div>
      <div className="">
        <a
          href="https://linkedin.com/in/johnny-touch-5609611b8"
          target="_blank"
          rel="noreferrer"
        >
          <Linkedin className={`mx-3 footer-icon ${color}`} size={30} color="white" />
        </a>
        <a href="https://github.com/coder3103" target="_blank" rel="noreferrer">
          <Github className={`mx-3 footer-icon ${color}`} size={30} color="white" />
        </a>
        <a href="mailto: coder3103@gmail.com">
          <EnvelopeOpenFill
            className={`mx-3 footer-icon ${color}`}
            size={30}
            color="white"
          />
        </a>
      </div>
      <p className="mb-0 fs-6">
        Copyright © 2022 <span className={`${color}-font-color`}>Johnny Touch</span>
      </p>
    </div>
  );
};

export default Footer;
