import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { useState, useEffect } from "react";

export const useImage = (imageFolderName) => {
  const storage = getStorage();
  const listRef = ref(storage, `images/${imageFolderName}`);
  const [imageList, setImageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    let fileNamesArray
    listAll(listRef)
      .then((res) => {
        fileNamesArray = res.items.map((itemRef) => itemRef.name);
        const promises = res.items.map((itemRef) => getDownloadURL(itemRef));
        return Promise.all(promises)
      })
      .then((urlArray) => {
        if(imageFolderName === 'matcher') {
          const objectsArray = urlArray.map((elem, index) => ({ name: fileNamesArray[index], imageURL: elem, matched: false }));
          setImageList(objectsArray);
        } else {
          const objectsArray = urlArray.map((elem, index) => ({ name: fileNamesArray[index], imageURL: elem }));
          setImageList(objectsArray);
        }
      })
  }, []);

  return { imageList}
};
