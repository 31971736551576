// css and bootstrap imports
import { Button } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./css/SchoolList.css";

// hooks
import { useGlobalContext } from "../hooks/useGlobalContext";

// other mports
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// component imports
import AddSchool from "../forms/AddSchool";

// react imports
import { useState, useEffect } from "react";

// firebase imports
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase/config";

// aos
import Aos from "aos";
import "aos/dist/aos.css";

const SchoolList = ({ schools }) => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const { color, user } = useGlobalContext();

  const deleteSchool = (id) => {
    deleteDoc(doc(db, "schools", id));
  };

  return (
    <div className="school-list">
      <Container className="my-4">
        <Row
          className="justify-content-center px-2 px-lg-0 px-xl-0"
          data-aos="flip-right"
          data-aos-duration="3000"
        >
          {schools.map((school) => (
            <Col md={6} lg={6} xl={4} key={school.id}>
              <Card className={`${color} mx-2 h-100`}>
                <LazyLoadImage
                  className="card-img-top"
                  variant="top"
                  src={school.schoolLogoURL}
                />
                <Card.Header className="pt-4">
                  <h3 style={{ fontSize: 28 }} className="black-ts">
                    {school.schoolName}
                  </h3>
                  <h4 style={{ fontSize: 20 }} className="mb-0 gemunu black-ts">
                    {school.degree}
                  </h4>
                  <p style={{ fontSize: 20 }} className="my-1 fs-5 black-ts">
                    {school.start.replace(/(\d{4})\-(\d{2}).*/, "$2-$1")} -{" "}
                    {school.end.replace(/(\d{4})\-(\d{2}).*/, "$1")}
                  </p>
                  {/* <ProgressBar
                    className="my-3"
                    now={
                      (school.completedCourses
                        .split(",")
                        .filter((element) => element).length /
                        school.totalCourses) *
                      100
                    }
                    visuallyHidden
                  /> */}
                </Card.Header>
                <Card.Footer>
                  <a href={school.schoolURL} target="_blank" rel="noreferrer">
                    <Button className={`my-3 ${color}-btn`}>Website Link</Button>
                  </a>
                  {user && <AddSchool data={school} />}
                </Card.Footer>
                {/* <Card.Body>
                  <p className="black-ts" style={{ fontSize: 20 }}>
                    Completed Courses
                  </p>
                  <ul>
                    {school.completedCourses
                      .split(",")
                      .filter((element) => element)
                      .map((x) => (
                        <li
                          key={Math.random()}
                          style={{ listStyleType: "circle" }}
                          className="text-start mb-2 gemunu"
                        >
                          {x}
                        </li>
                      ))}
                  </ul>
                  <p className="black-ts" style={{ fontSize: 20 }}>
                    Current Courses
                  </p>
                  <ul>
                    {school.currentCourses
                      .split(",")
                      .filter((element) => element)
                      .map((x) => (
                        <li
                          key={Math.random()}
                          style={{ listStyleType: "circle" }}
                          className="text-start mb-2 gemunu"
                        >
                          {x}
                        </li>
                      ))}
                  </ul>
                </Card.Body> */}
                {/* <Card.Footer>
                  {<Button
                    className="my-3 ms-4 orange-btn"
                    onClick={() => deleteSchool(school.id)}
                  >
                    Delete
                  </Button> }
                </Card.Footer> */}
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default SchoolList;
