import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyBZwtRriNcURNxebR-_jcALfpf_VXqJIAc",
    authDomain: "portfolio-8de9f.firebaseapp.com",
    projectId: "portfolio-8de9f",
    storageBucket: "portfolio-8de9f.appspot.com",
    messagingSenderId: "1080321982959",
    appId: "1:1080321982959:web:67bdd5a1f6319f9628361a",
    measurementId: "G-RPTWVK7RWL"
  };

// init firebase
const app = initializeApp(firebaseConfig)

// init firestore
const db = getFirestore()

// init storage
const storage = getStorage(app)

// init auth
const auth = getAuth()

export { db, storage, auth }