// react imports
import React, { useState, useEffect } from "react";

// css & bootstrap imports
import { Button, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Image } from "react-bootstrap";
import "../../css/Modals.css";
import { VolumeUp, VolumeMute } from "react-bootstrap-icons";
import './css/Icons.css'

// component imports
import SingleCard from "./SingleCard";
import Victory from "./Victory";
import Scoreboard from "./Scoreboard";
import Loading from "../../components/Loading";
import ReactAudioPlayer from "react-audio-player";

// hook imports
import { useImage } from "../../hooks/useImage";
import { useCollection } from "../../hooks/useCollection";
import { useGlobalContext } from "../../hooks//useGlobalContext";

// firebase imports
import { db, storage } from "../../firebase/config";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";

// images
import asteroid from "./images/asteroid.png";
import female from "./images/female.png";
import jupiter from "./images/jupiter.png";
import meteor from "./images/meteor.png";
import monkey from "./images/monkey.png";
import neptune from "./images/neptune.png";
import rocket from "./images/rocket.png";
import shuttle from "./images/shuttle.png";

// sounds
import astro from "./sounds/astro.mp3";
import match from "./sounds/match.mp3";
import nomatch from "./sounds/nomatch.mp3";
import winner from "./sounds/winner.mp3";


function Matcher() {
  const [gameShow, setGameShow] = useState(false);
  const [victoryShow, setVictoryShow] = useState(false);
  const [cards, setCards] = useState([]);
  const [turns, setTurns] = useState(0);
  const [correct, setCorrect] = useState(0);
  const [total, setTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [choiceOne, setChoiceOne] = useState(null);
  const [choiceTwo, setChoiceTwo] = useState(null);
  const [choiceThree, setChoiceThree] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [userName, setUserName] = useState("");
  const [topScore, setTopScore] = useState(0);
  const [coverCounter, setCoverCounter] = useState(0);
  const [imageCounter, setImageCounter] = useState(0);
  const [gameReady, setGameReady] = useState(false);
  const [soundOn, setSoundOn] = useState(false);

  const matcherList = [
    { name: asteroid },
    { name: female },
    { name: jupiter },
    { name: meteor },
    { name: monkey },
    { name: neptune },
    { name: rocket },
    { name: shuttle },
  ];

  const { color } = useGlobalContext();

  // shuffle cards before game starts or is reset

  const shuffleCards = () => {
    const shuffledCards = [...matcherList, ...matcherList, ...matcherList]
      .sort(() => Math.random() - 0.5)
      .map((card) => ({ ...card, id: Math.random() }));

    setCards(shuffledCards);
    setTurns(0);
    setCorrect(0);
    setUserName(userName ? userName : "Guest");
    setCoverCounter(0);
    setImageCounter(0);
  };

  // reset choices

  const resetTurn = () => {
    setTurns((prevTurns) => prevTurns + 1);
    setChoiceOne(null);
    setChoiceTwo(null);
    setChoiceThree(null);
    setDisabled(false);
  };

  // check which choice the user is on

  const handleChoice = (card) => {
    !choiceOne
      ? setChoiceOne(card)
      : !choiceTwo
      ? setChoiceTwo(card)
      : setChoiceThree(card);
  };

  // get all scores and usernames from firebase

  const {
    documents: matcherUserData,
    loading: matcherLoading,
    error: matcherError,
  } = useCollection("matcher");

  // push scores to firebase after completing game

  async function handlePush() {
    const timeStamp = Date.now();

    const data = {
      userName,
      turns,
      timeStamp,
    };

    try {
      await addDoc(collection(db, "matcher"), data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (coverCounter === 48 && imageCounter === 48) {
      setGameReady(true);
    }

    if (correct === 24) {
      if(soundOn){
        new Audio(winner).play();
      }
      setTopScore(matcherUserData[0].turns);
      setVictoryShow(true);
      handlePush();
    }

    if (choiceOne && choiceTwo && choiceThree) {
      setDisabled(true);
      if (
        choiceOne.name === choiceTwo.name &&
        choiceOne.name === choiceThree.name
      ) {
        if(soundOn){
          new Audio(match).play();
        }
        setCorrect((prevTurns) => prevTurns + 3);
        setGrandTotal((prevTurns) => prevTurns - 3);
        setCards((prevCards) => {
          return prevCards.map((card) => {
            if (card.name === choiceOne.name) {
              return { ...card, matched: true };
            } else {
              return card;
            }
          });
        });
      }
      else{
        if(soundOn){
          new Audio(nomatch).play();
        }
      }
      setTimeout(() => resetTurn(), 500);
    }
  }, [choiceOne, choiceTwo, choiceThree, coverCounter, imageCounter]);

  return (
    <>
      <div className="px-5">
        <Form.Control
          required
          value={userName}
          className="my-4"
          type="text"
          placeholder="Enter Player Name"
          onChange={(e) => setUserName(e.target.value)}
        />
      </div>
      <Button
        className={`${color}-btn mb-3`}
        onClick={() => {
          setGameShow(true);
          shuffleCards();
          setSoundOn(true);
        }}
      >
        Start Game
      </Button>

      <Modal
        backdrop="static"
        size="lg"
        show={gameShow}
        onHide={() => setGameShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="px-3 px-md-0 px-xl-0 px-lg-0"
        contentClassName={`${color}`}
      >
        <ReactAudioPlayer muted={!soundOn} loop={true} autoPlay={true} volume={0.5} src={astro} />
        <Modal.Header className="border-0 p-4" closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            className="koulen fs-3"
          >
            <h2 className="mb-0">Space Matcher 5000</h2>
          </Modal.Title>
        </Modal.Header>
        <div className="d-flex justify-content-center my-3">
        <VolumeUp onClick={() => setSoundOn(true)} className={`mx-3 volume`} size={40} color={soundOn ? (`${color}` === 'orange' ? '#ff6a00fe' : '#ff006afe') : 'white'} />
        <VolumeMute onClick={() => setSoundOn(false)} className={`mx-3 volume`} size={40} color={!soundOn ? (`${color}` === 'orange' ? '#ff6a00fe' : '#ff006afe') : 'white'} />
        </div>
        <div className="pt-3 d-flex text-center justify-content-evenly">
          <h6 className="gemunu text-white">
            Turns: <br />
            {turns}
          </h6>
          <h6 className="gemunu text-white">
            Matches: <br />
            {correct} / 24{" "}
          </h6>
          {matcherUserData && (
            <h6 className="gemunu text-white">
              Top Score: <br />
              {matcherUserData[0].turns ? matcherUserData[0].turns : ""} Turns
            </h6>
          )}
          {!matcherUserData && (
            <h6 className="gemunu text-white">
              Top Score: <br /> N/A
            </h6>
          )}
        </div>
        <Modal.Body>
          <Row>
            {cards.map((card) => (
              <SingleCard
                soundOn={soundOn}
                imageCounter={setImageCounter}
                coverCounter={setCoverCounter}
                key={card.id}
                card={card}
                handleChoice={handleChoice}
                flipped={
                  card === choiceOne ||
                  card === choiceTwo ||
                  card === choiceThree ||
                  card.matched
                }
                matched={card.matched}
                disabled={disabled}
              />
            ))}
          </Row>
        </Modal.Body>
        <div className="text-center my-4">
          {!gameReady && <Loading />}
          <Button
            className={`${color}-btn me-2`}
            onClick={() => {
              shuffleCards();
            }}
          >
            Reset Game
          </Button>
          <Scoreboard
            shuffleCards={shuffleCards}
            matcherUserData={matcherUserData}
          />
        </div>
      </Modal>
      <Victory
        topScore={topScore}
        matcherUserData={matcherUserData}
        shuffleCards={shuffleCards}
        userName={userName}
        turns={turns}
        victoryShow={victoryShow}
        setVictoryShow={setVictoryShow}
      />
    </>
  );
}

export default Matcher;
