// css & bootstrap imports
import Modal from "react-bootstrap/Modal";
import { Image } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "./css/Victory.css";

// component imports
import Loading from "../../components/Loading";
import Scoreboard from "./Scoreboard";

// images imports
import accomplished from "./images/accomplished.png";
import first from "./images/first.png";

// react imports
import { useState, useEffect } from "react";

// hook imports
import { useGlobalContext } from "../../hooks/useGlobalContext";

const Victory = ({
  topScore,
  userName,
  turns,
  victoryShow,
  setVictoryShow,
  shuffleCards,
  matcherUserData,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const { color } = useGlobalContext();

  return (
    <>
      <Modal
        backdrop="static"
        size="md"
        show={victoryShow}
        onHide={() => setVictoryShow(false)}
        aria-labelledby="example-modal-sizes-title-md"
        centered
        className="p-0"
        contentClassName={`${color}`}
      >
        <Modal.Header className="border-0 p-4" closeButton>
          <Modal.Title id="example-modal-sizes-title-md"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="koulen text-center">
          <h1>Mission Accomplished!</h1>
          {topScore && topScore <= turns && (
            <>
              <Image
                className="m-4 swing"
                height="200"
                width="200"
                src={accomplished}
                onLoad={() => {
                  setImageLoaded(true);
                }}
              />
              {!imageLoaded && <Loading />}
              <h3>
                Well done{" "}
                <span className={`fs-2 ${color}-font-color`}>
                  {userName ? userName : "Guest"}
                </span>
                !
              </h3>
              <h5>
                You've completed the game in{" "}
                <span className={`fs-3 ${color}-font-color`}>{turns}</span>{" "}
                turns!
              </h5>
              <h5>
                You needed{" "}
                <span className="fs-3 text-success">
                  {matcherUserData[0].turns - turns === 0 ? -1 : matcherUserData[0].turns - turns}
                </span>{" "}
                turns to beat the current top score of{" "}
                <span className={`fs-3 ${color}-font-color`}>
                  {matcherUserData[0].turns}
                </span>
                !
              </h5>
            </>
          )}
          {topScore && topScore > turns && (
            <>
              <Image
                className="m-4 swing"
                height="200"
                width="200"
                src={first}
                onLoad={() => {
                  setImageLoaded(true);
                }}
              />
              {!imageLoaded && <Loading />}
              <h3>
                Well done{" "}
                <span className={`fs-2 ${color}-font-color`}>
                  {userName ? userName : "Guest"}
                </span>
                !
              </h3>
              <h5>
                You've completed the game in{" "}
                <span className={`fs-3 ${color}-font-color`}>{turns}</span>{" "}
                turns and set a new{" "}
                <span className={`fs-3 ${color}-font-color`}>record</span>!{" "}
              </h5>
            </>
          )}
            
          <div className="mt-3 mb-2">
          <Button
            className={`${color}-btn mx-3`}
            onClick={() => {
              shuffleCards();
              setVictoryShow(false);
            }}
          >
            Play Again
          </Button>
          <Scoreboard
            matcherUserData={matcherUserData}
          />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Victory;
