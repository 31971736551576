// css & bootstrap imports
import "./css/SingleCard.css";
import Col from "react-bootstrap/Col";

// component imports
import Loading from "../../components/Loading";
import ReactAudioPlayer from "react-audio-player";

// react imports
import { useState } from "react";

// hook imports
import { useGlobalContext } from "../../hooks//useGlobalContext";

// imaages 
import cover from './images/cover.png'

// sounds
import select from "./sounds/select.mp3";

export default function SingleCard({
  card,
  handleChoice,
  flipped,
  matched,
  disabled,
  coverCounter,
  imageCounter,
  soundOn
}) {

  const { color } = useGlobalContext();

  const handleClick = () => {
    if (!disabled) {
      handleChoice(card);
      if(soundOn){
        new Audio(select).play();
      }

    }
  };

  return (
    <>
      <Col className="py-3 text-center xsm-none" sm={4} md={3} lg={2} xl={2}>
        <div className={flipped ? "flipped" : "matcher-spin"}>
          <div className={matched ? "matched" : "unmatched"}>
            <img
              className="front rounded-circle"
              width="75"
              height="75"
              src={card.name}
              alt="game"
              onLoad={() => {
                imageCounter(prev => prev + 1);
              }}
            />
            <img
              className={`back-${color} rounded-circle`}
              width="75"
              height="75"
              src={cover}
              alt="cover"
              onClick={handleClick}
              onLoad={() => {
                coverCounter((prev) => prev + 1);
              }}
            />
          </div>
        </div>
      </Col>
      <Col className="py-3 text-center xsm-block" xs={3}>
        <div className={flipped ? "flipped" : "matcher-spin"}>
          <div className={matched ? "matched" : "unmatched"}>
            <img
              className="front rounded-circle"
              width="45"
              height="45"
              src={card.name}
              alt="game"
              onLoad={() => {
                imageCounter(prev => prev + 1);
              }}
            />
            <img
              className={`back-${color} rounded-circle`}
              width="45"
              height="45"
              src={cover}
              alt="cover"
              onClick={handleClick}
              onLoad={() => {
                coverCounter(prev => prev + 1);
              }}
            />
          </div>
        </div>
      </Col>
    </>
  );
}
