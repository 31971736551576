// assets
import orange_tags from './images/orange_tags.png'
import purple_tags from './images/purple_tags.png'

// react imports
import React from "react";
import { useState, useEffect, useRef } from "react";

// cass & boostrap imports
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { Button, Spinner } from "react-bootstrap";
import "./css/SignInOut.css";
import "./../css/Buttons.css";
import "./../css/Modals.css";

// firebase imports
import { auth } from "../firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";

// component imports
import { useGlobalContext } from "../hooks/useGlobalContext";
import Loading from "../components/Loading";

function MyVerticallyCenteredModal(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submit, setSubmit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const { color, dispatch } = useGlobalContext();

  useEffect(() => {
    setEmail("");
    setPassword("");
    setSubmit(false);
    setSuccess(false);
    setError(false);
  }, [props.show]);

  async function handleSubmit(e) {
    e.preventDefault();

    setSubmit(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        setSubmit(false);
        setError(false);
        setSuccess(true);
        dispatch({ type: "LOGIN", payload: res.user });
        setTimeout(() => {
          props.onHide();
        }, 1000);
      })
      .catch((err) => {
        setSubmit(false);
        setSuccess(false);
        setError(err.message);
      });
  }

  return (
    <Modal
      {...props}
      backdrop="static"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName={`${color}`}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="koulen">
          Admin Login
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mx-3">
        <Form id="loginForm" onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label className="koulen">Email</Form.Label>
            <Form.Control
              value={email}
              required
              type="email"
              placeholder="Email Address"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="password">
            <Form.Label className="koulen">Password</Form.Label>
            <Form.Control
              value={password}
              required
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <div className="text-center">
            {success && (
                <Alert.Heading className="mb-3 fs-6 koulen">
                  Logged In Successfully!
                </Alert.Heading>
            )}
            {error && (
                <Alert.Heading className="mb-3 fs-6 koulen">
                  Login Error! Please Try Again!
                </Alert.Heading>
            )}
            <Button className={`${color}-btn`} type="submit">
              {submit && (
                <Spinner
                  className="me-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              login
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

const SignInOut = () => {

  const [modalShow, setModalShow] = React.useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { dispatch, user, color } = useGlobalContext();

  const logoutUser = async () => {
    setError(null);
    setLoading(true);

    try {
      await auth.signOut();
      dispatch({ type: "LOGOUT" });
      setError(null);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      setError(err.message);
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      {loading && <Loading />}

      {!user && (
        <>
        <img
            alt=""
            src={color === 'orange' ? orange_tags : purple_tags}
            width="50"
            height="50"
            className="pointer rounded-circle border border-4"
            onClick={() => setModalShow(true)}
          />{" "}

          {/* <Button
            className="orange-btn position-absolute me-5 top-0 end-0 px-4 xsm-none"
             onClick={() => setModalShow(true)}           
          >
            Login
          </Button> */}

          {/* <GridFill
            color="white"
            size={20}
            className="loginout position-absolute me-4 top-0 end-0 xsm-block"
            onClick={() => setModalShow(true)}
          /> */}
        </>
      )}

      {user && (
        <>
        <img
            alt=""
            src={color === 'orange' ? orange_tags : purple_tags}
            width="50"
            height="50"
            className="img-spin pointer rounded-circle border border-4"
            onClick={() => logoutUser()}
          />{" "}

          
          {/* <Button
            className="orange-btn position-absolute me-5 top-0 end-0 px-4 xsm-none"
            onClick={() => logoutUser()}
          >
            Logout
          </Button>

          <GridFill
            color="#ea600b"
            size={20}
            className="loginout position-absolute me-4 top-0 end-0 xsm-block"
            onClick={() => logoutUser()}
          /> */}
        </>
      )}

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default SignInOut;
