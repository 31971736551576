// react imports
import React from "react";
import { useState, useEffect, useRef } from "react";

// boostrap imports
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { Button, Spinner } from "react-bootstrap";

// firebase imports
import { db, storage } from "../firebase/config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";

// hook imports
import { useGlobalContext } from "../hooks/useGlobalContext";

// aos
import Aos from "aos";
import "aos/dist/aos.css";

function MyVerticallyCenteredModal(props) {
  const [name, setCertName] = useState("");
  const [organization, setOrganization] = useState("");
  const [orgURL, setOrgUrl] = useState("");
  const [certLogoURL, setCertLogoURL] = useState("");
  const [certLogo, setCertLogo] = useState("");
  const [certURL, setCertURL] = useState("");
  const [start, setStart] = useState("");
  const [submit, setSubmit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const fileRef = useRef();

  const { color } = useGlobalContext();

  useEffect(() => {
    if (props.data) {
      setCertName(props.data.name ? props.data.name : "");
      setOrganization(props.data.organization ? props.data.organization : "");
      setOrgUrl(props.data.orgURL ? props.data.orgURL : "");
      setCertLogoURL(props.data.certLogoURL ? props.data.certLogoURL : "");
      setStart(props.data.start ? props.data.start : "");
      setCertLogo(props.data.certLogo ? props.data.certLogo : "");
      setCertURL(props.data.certURL ? props.data.certURL : "");
      setSubmit(false);
      setSuccess(false);
      setError(false);
    } else {
      setCertName("");
      setOrganization("");
      setOrgUrl("");
      setCertLogoURL("");
      setStart("");
      setCertLogo("");
      setCertURL("");
      setSubmit(false);
      setSuccess(false);
      setError(false);
    }
  }, [props.data, props.show]);

  async function handleSubmit(e) {
    e.preventDefault();

    setSubmit(true);

    const data = {
      name,
      organization,
      orgURL,
      start,
      certURL,
      certLogoURL,
    };

    try {
      if (props.data) {
        await updateDoc(doc(db, "certs", props.data.id), data).then(() => {
          if (certLogo) {
            const imageRef = ref(storage, `images/certs/${certLogo.name}`);
            uploadBytes(imageRef, certLogo).then((snapshot) => {
              getDownloadURL(snapshot.ref).then((url) =>
                updateDoc(doc(db, "certs", props.data.id), {
                  certLogoURL: url,
                })
              );
            });
          }
        });
        setSuccess(true);
        setError(false);
      } else if (!props.data) {
        await addDoc(collection(db, "certs"), data).then((docRef) => {
          if (certLogo) {
            const imageRef = ref(storage, `images/certs/${certLogo.name}`);
            uploadBytes(imageRef, certLogo).then((snapshot) => {
              getDownloadURL(snapshot.ref).then((url) =>
                updateDoc(doc(db, "certs", docRef.id), { certLogoURL: url })
              );
            });
          }
        });
        setSuccess(true);
        setError(false);
      }
    } catch (err) {
      console.log(err);
      setSuccess(false);
      setError(true);
      setSubmit(false);
    } finally {
      if (!props.data) {
        setCertName();
        setOrganization();
        setOrgUrl();
        setCertLogoURL();
        setStart();
        setCertLogo();
        setCertURL();
        setSubmit(false);
        fileRef.current.value = "";
      } else {
        setSubmit(false);
      }
    }
  }

  return (
    <Modal
      {...props}
      backdrop="static"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName={`${color}`}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="koulen">
          {props.data ? "Update CertInformation" : "New Cert Form"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="certForm" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label className="koulen">Certification Name</Form.Label>
            <Form.Control
              value={name}
              required
              type="text"
              placeholder="Certification Name"
              onChange={(e) => setCertName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="startDate">
            <Form.Label className="koulen">Start Date</Form.Label>
            <Form.Control
              value={start}
              pattern="\d{4}-\d{2}-\d{2}"
              required
              type="date"
              placeholder="Start Date"
              onChange={(e) => setStart(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="koulen">Certification URL</Form.Label>
            <Form.Control
              value={certURL}
              required
              type="url"
              placeholder="Certification URL"
              onChange={(e) => setCertURL(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="koulen">Certification Logo</Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => setCertLogo(e.target.files[0])}
              ref={fileRef}
            />
            {certLogoURL && (
              <p className="pt-3 w-100 text-truncate koulen">
                Current Url: {certLogoURL}
              </p>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="koulen">Organization</Form.Label>
            <Form.Control
              value={organization}
              required
              type="text"
              placeholder="Organization Name"
              onChange={(e) => setOrganization(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="koulen">Organization URL</Form.Label>
            <Form.Control
              value={orgURL}
              type="url"
              placeholder="School URL"
              onChange={(e) => setOrgUrl(e.target.value)}
            />
          </Form.Group>

          <div className="text-center">
            {success && (
              <Alert.Heading className="mb-3 fs-6 koulen">
                {props.data
                  ? "Cert Updated Successfully!"
                  : "Cert Added Successfully!"}
              </Alert.Heading>
            )}

            {error && (
              <Alert.Heading className="mb-3 fs-6 koulen">
                Error Alert! Please Check Console!
              </Alert.Heading>
            )}
            <Button className={`${color}-btn`} type="submit">
              {submit && (
                <Spinner
                  className="me-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

const AddCert = ({ data }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [certData, setCertData] = React.useState(false);
  const { color } = useGlobalContext();

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {data && (
        <Button
          className={`${color}-btn my-3 ms-4`}
          onClick={() => {
            setModalShow(true);
            setCertData(data);
          }}
        >
          Update
        </Button>
      )}

      {!data && (
        <div>
          <span className={`${color}-bar-1 d-block my-5 mx-auto`} data-aos="fade-up">
            '
          </span>
          <Button
            data-aos="fade-up"
            className={`${color}-btn mb-5`}
            onClick={() => setModalShow(true)}
          >
            Add New Cert
          </Button>
        </div>
      )}

      <MyVerticallyCenteredModal
        data={certData ? certData : undefined}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default AddCert;
