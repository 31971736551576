// css & bootstrap imports
import "./App.css";

// component imports
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import About from "./components/About";
import Resume from "./components/Resume";
import Projects from "./components/ProjectList";

// react imports
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// custom hooks 
import { useGlobalContext } from "./hooks/useGlobalContext";

function App() {
  return (
    <div className="App">
      <Router>
        <Navigation/>
        <Routes>
          <Route path="/" element={<About/>}/>
          <Route path="/resume" element={<Resume/>}/>
          <Route path="/projects" element={<Projects/>}/>
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
