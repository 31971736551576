// assets
import headshot from './images/navigation/headshot.jpg'
import abstract from './videos/abstract.mp4'

// css & bootstrap imports
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "./css/Navigation.css";
import "./../css/Fonts.css";

// react imports
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";

// component imports
import SignInOut from "../forms/SignInOut";
import Loading from "./Loading";

// hooks
import { useGlobalContext } from "../hooks/useGlobalContext";

const Navigation = () => {

  const { color, dispatch } = useGlobalContext();

  const [headshotLoad, setHeadshotLoad] = useState(false);
  const [videoLoad, setVideoLoad] = useState(false);

  const circleFormation = () => {
    let list = [];
    for (let x = 1; x <= 16; x++) {
      list.push(
        <div key={`circle_${x}`} className={`circle_${x} ${color}`}></div>
      );
    }
    return list;
  };

  useEffect(() => {
  }, [headshotLoad, videoLoad])

  return (
    <div>
      <div className={`navigation ${color}`}>
        <Navbar className="py-0">
          <video
            autoPlay
            loop
            muted
            width="100%"
            style={{ objectFit: "cover", height: "535px" }}
            onLoadedData={() => {
              setVideoLoad(true);
            }}
          >
            <source src={abstract} type="video/mp4" />
          </video>
          <div className="position-absolute w-100 mt-5 top-0">
            {circleFormation()}
            <Navbar.Brand className="p-0 m-0">
              <img
                width="150"
                height="150"
                className={`headshot ${color} my-5 rounded-circle`}
                src={headshot}
                alt="logo"
                onLoad={() => {
                  setHeadshotLoad(true);
                }}
              />
            </Navbar.Brand>
            <Nav.Item
              className={`${color}-ts-2 text-white mt-3 mt-md-2 mt-lg-2 mt-xl-2 pt-5`}
            >
              <h1>
                let <span className="serif">I</span> = "Johnny Touch"
              </h1>
            </Nav.Item>
            <div className="d-flex justify-content-center align-items-center mt-4 mt-md-3 mt-lg-3 mt-xl-3">
              <div
                className={`orange-theme ${color} mx-3 border border-3`}
                onClick={() =>
                  dispatch({ type: "CHANGE_THEME", payload: "orange" })
                }
              />
              <SignInOut />
              <div
                className={`purple-theme ${color} mx-3 border border-3`}
                onClick={() =>
                  dispatch({ type: "CHANGE_THEME", payload: "purple" })
                }
              />
            </div>
            {(!headshotLoad || !videoLoad) && <Loading />}
            <Nav
              className={`justify-content-center mt-3 mt-md-2 mt-lg-2 mt-xl-2 ${color}`}
            >
              <NavLink
                className={`xsm-none fs-4 ${color}-ts-2 text-center text-white text-decoration-none p-2`}
                to="/"
              >
                About
              </NavLink>
              <NavLink
                className={`xsm-none fs-4 ${color}-ts-2 text-center text-white text-decoration-none p-2`}
                to="/resume"
              >
                Resume
              </NavLink>
              <NavLink
                className={`xsm-none fs-4 ${color}-ts-2 text-center text-white text-decoration-none p-2`}
                to="/projects"
              >
                Projects
              </NavLink>
              <NavLink
                className={`xsm-block fs-4 ${color}-ts-2 text-center text-white text-decoration-none p-2`}
                to="/"
              >
                About
              </NavLink>
              <NavLink
                className={`xsm-block fs-4 ${color}-ts-2 text-center text-white text-decoration-none p-2`}
                to="/resume"
              >
                Resume
              </NavLink>
              <NavLink
                className={`xsm-block fs-4 ${color}-ts-2 text-center text-white text-decoration-none p-2`}
                to="/projects"
              >
                Projects
              </NavLink>
            </Nav>
          </div>
        </Navbar>
      </div>
    </div>
  );
};

export default Navigation;
