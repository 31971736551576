// react imports
import { useState, useEffect } from "react";

// firebase imports
import { db } from "../firebase/config";
import { collection, onSnapshot, query, orderBy} from "firebase/firestore";

export const useCollection = (collectionName) => {
    const [documents, setDocuments] = useState(null)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);

        let ref;
      
        if(collectionName === "matcher") {
          ref = query(collection(db, collectionName), orderBy('turns', 'asc'));
        } else {
          ref = query(collection(db, collectionName), orderBy('start', 'desc'));
        }

    
        const unsub = onSnapshot(
          ref,
          (snapshot) => {
            if (snapshot.empty) {
              setError("No Data Available");
              setLoading(false);
            } else {
              let results = [];
              snapshot.docs.forEach((doc) => {
                results.push({ ...doc.data(), id: doc.id });
              });
              setDocuments(results);
              setLoading(false);
            }
          },
          (err) => {
            setError(err.message);
            setLoading(false);
          }
        );
        return () => unsub();
      }, [collectionName]);

      return { documents, loading, error }
}