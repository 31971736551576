// component imports
import JobList from "./JobList";
import SchoolList from "./SchoolList";
import AddJob from "../forms/AddJob";
import AddSchool from "../forms/AddSchool";
import { useCollection } from "../hooks/useCollection";
import { useGlobalContext } from "../hooks/useGlobalContext";
import CertList from "./CertList";
import AddCert from "../forms/AddCert";


// bootstrap and css imports
import Loading from "./Loading";
import '../css/Logo.css'

// aos
import Aos from "aos";
import "aos/dist/aos.css";

// react imports
import { useEffect } from "react";

const Resume = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  const { dispatch, user, color } = useGlobalContext();
  const {
    documents: jobs,
    loading: jobLoading,
    error: jobError,
  } = useCollection("jobs");
  const {
    documents: schools,
    loading: schoolLoading,
    error: schoolError,
  } = useCollection("schools");
  const {
    documents: certs,
    loading: certLoading,
    error: certError,
  } = useCollection("certs");
  return (
    <div className="home">
      {jobError && <p className="my-5">Data Cannot Be Retrieved..</p>}
      {(jobLoading || schoolLoading || certLoading) && <Loading />}
      <h2 className={`${color}-border`} style={{ marginTop: 50 }}>
        resume
      </h2>
      <span className={`${color}-bar-1 my-5`}>'</span>
      <h2 className={`${color}-border`} data-aos="fade-up">
        work experience
      </h2>
      <span className={`${color}-bar-1 my-5`} data-aos="fade-up">
        '
      </span>
      {jobs && (
        <JobList jobs={jobs}/>
      )}
      {user && <AddJob />}
      <span className={`${color}-bar-1 my-5 mt-lg-0 mt-xl-0`} data-aos="fade-up">'</span>
      <h2 className={`${color}-border mb-0`} data-aos="fade-up">education</h2>
      <span className={`${color}-bar-1 my-5`} data-aos="fade-up">'</span>
      {schoolError && <p className="my-5">Data Cannot Be Retrieved..</p>}
      {schools && <SchoolList schools={schools} />}
      {user && <AddSchool />}
      <span className={`${color}-bar-1 my-5`} data-aos="fade-up">'</span>
      <h2 className={`${color}-border mb-0`} data-aos="fade-up">certifications</h2>
      <span className={`${color}-bar-1 mt-5`} data-aos="fade-up">'</span>
      {certError && <p className="my-5">Data Cannot Be Retrieved..</p>}
      {certs && <CertList certs={certs} />}
      {user && <AddCert />}
    </div>
  );
};

export default Resume;
