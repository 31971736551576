// css and bootstrap imports
import { Button } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { ArrowLeftCircle, ArrowRightCircle } from "react-bootstrap-icons";
import Image from "react-bootstrap/Image";
import AddCert from "../forms/AddCert";
import "./../css/Fonts.css";
import "./../css/CardsCarousel.css";

// component imports
import { useGlobalContext } from "../hooks/useGlobalContext";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// react imports
import { useEffect } from "react";

// firebase imports
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase/config";

// aos
import Aos from "aos";
import "aos/dist/aos.css";

const CertList = ({ certs }) => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const { user, color } = useGlobalContext();

  const deleteCert = (id) => {
    deleteDoc(doc(db, "certs", id));
  };

  return (
    <div className="cert-list">
      <Container className="mb-5">
        <Row
          className="xsm-none"
          data-aos="flip-right"
          data-aos-duration="3000"
        >
          {certs &&
            certs.map((cert) => (
              <Col className="mt-5" md={6} lg={6} xl={4} key={cert.id}>
                <Card className={`${color} mx-2 h-100`}>
                  <LazyLoadImage
                    className="card-img-top"
                    variant="top"
                    src={cert.certLogoURL}
                  />
                  <Card.Header className="pt-4 h-100">
                    <h4 style={{ fontSize: 28 }} className="black-ts">
                      <a
                        className="text-decoration-none text-white"
                        href={cert.orgURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {cert.organization}
                      </a>
                    </h4>
                    <h4
                      style={{ fontSize: 20 }}
                      className="mb-0 gemunu black-ts "
                    >
                      {cert.name}
                    </h4>
                    <p style={{ fontSize: 20 }} className="my-1 black-ts">
                      {cert.start.replace(/(\d{4})\-(\d{2}).*/, "$2-$1")}
                    </p>
                    {/* {user && (
                      <Button
                      className={`my-3 ms-4 ${color}-btn`}
                        onClick={() => deleteCert(cert.id)}
                      >
                        Delete
                      </Button>
                    )} */}
                  </Card.Header>
                  <Card.Footer>
                    <a href={cert.certURL} target="_blank" rel="noreferrer">
                      <Button className={`my-3 ${color}-btn`}>
                        Credential Link
                      </Button>
                    </a>
                    {user && <AddCert data={cert} />}
                  </Card.Footer>
                </Card>
              </Col>
            ))}
        </Row>

        <Carousel
          className="xsm-block mb-3 px-2"
          interval={null}
          fade
          indicators={false}
          data-aos="flip-right"
          data-aos-duration="3000"
        >
          {certs.map((cert) => (
            <Carousel.Item key={cert.id} className="h-100">
              <Card className={`${color} m-2 h-100`}>
                <Image
                  className="card-img-top"
                  variant="top"
                  src={cert.certLogoURL}
                />
                <Card.Header className="pt-4 h-100">
                  <h4 style={{ fontSize: 24 }} className="mb-0 black-ts">
                    <a
                      className="text-decoration-none text-white"
                      href={cert.orgURL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {cert.organization}
                    </a>
                  </h4>
                  <h4 style={{ fontSize: 18 }} className="my-1 gemunu black-ts">
                    {cert.name}
                  </h4>
                  <div className="d-flex justify-content-between">
                    <ArrowLeftCircle className="my-auto" size={20} />
                    <p style={{ fontSize: 20 }} className="my-1 black-ts">
                      {cert.start.replace(/(\d{4})\-(\d{2}).*/, "$2-$1")}
                    </p>
                    <ArrowRightCircle className="my-auto" size={20} />
                  </div>
                </Card.Header>
                <Card.Footer>
                    <a href={cert.certURL} target="_blank" rel="noreferrer">
                      <Button className={`my-3 ${color}-btn`}>
                        Credential Link
                      </Button>
                    </a>
                    {user && <AddCert data={cert} />}
                                      {/* {user && (
                    <Button
                      className={`my-3 ms-4 ${color}-btn`}
                      onClick={() => deleteCert(cert.id)}
                    >
                      Delete
                    </Button>
                  )} */}
                  </Card.Footer>
              </Card>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

export default CertList;
