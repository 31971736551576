// react imports
import React from "react";
import { useState, useEffect, useRef } from "react";

// boostrap imports
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { Button, Spinner } from "react-bootstrap";

// firebase imports
import { db, storage } from "../firebase/config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";

// hook imports
import { useGlobalContext } from "../hooks/useGlobalContext";

// aos
import Aos from "aos";
import "aos/dist/aos.css";

function MyVerticallyCenteredModal(props) {
  const [completedCourses, setCompletedCourses] = useState("");
  const [currentCourses, setCurrentCourses] = useState("");
  const [degree, setDegree] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [description, setDescription] = useState("");
  const [schoolLogoURL, setSchoolLogoURL] = useState("");
  const [schoolLogo, setSchoolLogo] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [schoolURL, setSchoolURL] = useState("");
  const [totalCourses, setTotalCourses] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const fileRef = useRef();

  const { color } = useGlobalContext();

  useEffect(() => {
    if (props.data) {
      setCompletedCourses(
        props.data.completedCourses ? props.data.completedCourses : ""
      );
      setCurrentCourses(
        props.data.currentCourses ? props.data.currentCourses : ""
      );
      setStart(props.data.start ? props.data.start : "");
      setEnd(props.data.end ? props.data.end : "");
      setDegree(props.data.degree ? props.data.degree : "");
      setDescription(props.data.description ? props.data.description : "");
      setSchoolURL(props.data.schoolURL ? props.data.schoolURL : "");
      setSchoolLogoURL(
        props.data.schoolLogoURL ? props.data.schoolLogoURL : ""
      );
      setTotalCourses(props.data.totalCourses ? props.data.totalCourses : "");
      setSchoolName(props.data.schoolName ? props.data.schoolName : "");
      setSchoolLogo(props.data.schoolLogo ? props.data.schoolLogo : "");
      setTotalCourses(props.data.totalCourses ? props.data.totalCourses : 0);
      setSubmit(false);
      setSuccess(false);
      setError(false);
    } else {
      setCompletedCourses("");
      setCurrentCourses("");
      setStart("");
      setEnd("");
      setDegree('');
      setDescription("");
      setSchoolURL("");
      setSchoolLogoURL("");
      setTotalCourses("");
      setSchoolName("");
      setTotalCourses(0);
      setSubmit(false);
      setSuccess(false);
      setError(false);
    }
  }, [props.data, props.show]);

  async function handleSubmit(e) {
    e.preventDefault();

    setSubmit(true);

    const data = {
      completedCourses,
      currentCourses,
      degree,
      start,
      end,
      description,
      schoolLogoURL,
      schoolName,
      schoolURL,
      totalCourses,
    };

    try {
      if (props.data) {
        await updateDoc(doc(db, "schools", props.data.id), data).then(() => {
          if (schoolLogo) {
            const imageRef = ref(
              storage,
              `images/schools/${schoolLogo.name}`
            );
            uploadBytes(imageRef, schoolLogo).then((snapshot) => {
              getDownloadURL(snapshot.ref).then((url) =>
                updateDoc(doc(db, "schools", props.data.id), {
                  schoolLogoURL: url,
                })
              );
            });
          }
        });
        setSuccess(true);
        setError(false);
      } else if (!props.data) {
        await addDoc(collection(db, "schools"), data).then((docRef) => {
          if (schoolLogo) {
            const imageRef = ref(
              storage,
              `images/schools/${schoolLogo.name}`
            );
            uploadBytes(imageRef, schoolLogo).then((snapshot) => {
              getDownloadURL(snapshot.ref).then((url) =>
                updateDoc(doc(db, "schools", docRef.id), { schoolLogoURL: url })
              );
            });
          }
        });
        setSuccess(true);
        setError(false);
      }
    } catch (err) {
      console.log(err);
      setSuccess(false);
      setError(true);
      setSubmit(false);
    } finally {
      if (!props.data) {
        setCompletedCourses("");
        setCurrentCourses("");
        setStart("");
        setEnd("");
        setDegree("");
        setDescription("");
        setSchoolURL("");
        setSchoolLogoURL("");
        setTotalCourses(0);
        setSchoolName("");
        setSubmit(false);
        fileRef.current.value = "";
      } else {
        setSubmit(false);
      }
    }
  }

  return (
    <Modal
      {...props}
      backdrop="static"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName={`${color}`}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="koulen">
          {props.data ? "Update School Information" : "New School Form"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mx-3">
        <Form id="jobForm" onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="schoolName">
            <Form.Label className="koulen">School Name</Form.Label>
            <Form.Control
              value={schoolName}
              required
              type="text"
              placeholder="School Name"
              onChange={(e) => setSchoolName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="degree">
            <Form.Label className="koulen">Degree and Major</Form.Label>
            <Form.Control
              value={degree}
              required
              type="text"
              placeholder="Degree and Major"
              onChange={(e) => setDegree(e.target.value)}
            />
          </Form.Group>

          <div className="d-flex align-content-center">
            <Form.Group className="mb-3" controlId="startDate">
              <Form.Label className="koulen">Start Date</Form.Label>
              <Form.Control
                value={start}
                pattern="\d{4}-\d{2}-\d{2}"
                required
                type="date"
                placeholder="Start Date"
                onChange={(e) => setStart(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="ms-lg-5 ms-2 mb-3" controlId="endDate">
              <Form.Label className="koulen">End Date</Form.Label>
              <Form.Control
                pattern="\d{1,2}/\d{1,2}/\d{4}"
                value={end}
                type="date"
                placeholder="End Date"
                onChange={(e) => setEnd(e.target.value)}
              />
            </Form.Group>
          </div>

          <Form.Group className="mb-3" controlId="totalCourses">
            <Form.Label className="koulen">Total Courses</Form.Label>
            <Form.Control
              value={totalCourses}
              type="number"
              placeholder="Total Courses"
              onChange={(e) => setTotalCourses(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="completedCourses">
            <Form.Label className="koulen">Completed Courses</Form.Label>
            <Form.Control
              value={completedCourses}
              type="text"
              placeholder="Completed Courses"
              onChange={(e) => setCompletedCourses(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="currentCourses">
            <Form.Label className="koulen">Current Courses</Form.Label>
            <Form.Control
              value={currentCourses}
              type="text"
              placeholder="Current Courses"
              onChange={(e) => setCurrentCourses(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="schoolWebsite">
            <Form.Label className="koulen">School Website</Form.Label>
            <Form.Control
              value={schoolURL}
              type="url"
              placeholder="School URL"
              onChange={(e) => setSchoolURL(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="schoolLogo">
            <Form.Label className="koulen">School Logo</Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => setSchoolLogo(e.target.files[0])}
              ref={fileRef}
            />
            {schoolLogoURL && (
              <p className="pt-3 text-white w-100 text-truncate">
                Current Url: {schoolLogoURL}
              </p>
            )}
          </Form.Group>

          {/* <Form.Group className="mb-3" controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              required
              className="form-control"
              placeholder="Job Description"
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group> */}

          <div className="text-center">
            {success && (
                <Alert.Heading className="mb-3 fs-6 koulen">
                  {props.data
                    ? "School Updated Successfully!"
                    : "School Added Successfully!"}
                </Alert.Heading>
            )}

            {error && (
                <Alert.Heading className="mb-3 fs-6 koulen">
                  Error Alert! Please Check Console!
                </Alert.Heading>
            )}
            <Button className={`${color}-btn`} type="submit">
              {submit && (
                <Spinner
                  className="me-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

const AddSchool = ({ data }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [schoolData, setSchoolData] = React.useState(false);
  const { color } = useGlobalContext();

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {data && (
        <Button
          className={`${color}-btn my-3 ms-4`}
          onClick={() => {
            setModalShow(true);
            setSchoolData(data);
          }}
        >
          Update
        </Button>
      )}

      {!data && (
        <div>
          <span className={`${color}-bar-1 d-block my-5 mx-auto`} data-aos="fade-up">'</span>
          <Button data-aos="fade-up" className={`${color}-btn`} onClick={() => setModalShow(true)}>
            Add New School
          </Button>
        </div>
      )}

      <MyVerticallyCenteredModal
        data={schoolData ? schoolData : undefined}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default AddSchool;
