// assets
import orange_bugs from './images/about/orange_bugs.png'
import orange_coder from './images/about/orange_coder.png'
import orange_contact from './images/about/orange_contact.png'
import orange_design from './images/about/orange_design.png'
import orange_features from './images/about/orange_features.png'
import purple_bugs from './images/about/purple_bugs.png'
import purple_coder from './images/about/purple_coder.png'
import purple_contact from './images/about/purple_contact.png'
import purple_design from './images/about/purple_design.png'
import purple_features from './images/about/purple_features.png'

import bootstrap from './images/logos/bootstrap_logo.png'
import js from './images/logos/js_logo.png'
import react from './images/logos/react_logo.png'
import firebase from './images/logos/firebase_logo.png'

// css & bootstrap
import Carousel from "react-bootstrap/Carousel";
import { Container } from "react-bootstrap";
import { ArrowLeftCircle, ArrowRightCircle } from "react-bootstrap-icons";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./../css/Fonts.css";
import "./../css/CardsCarousel.css";
import "./../css/Buttons.css";

// react imports
import { useEffect } from "react";

// component imports
import ContactForm from "../forms/ContactForm";

// aos
import Aos from "aos";
import "aos/dist/aos.css";

// hook imports
import { useGlobalContext } from "../hooks/useGlobalContext";

const About = () => {

  const { color } = useGlobalContext();

  const logoStack = [bootstrap, js, react, firebase];

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="about">
      <Container>
        <h2 className={`mt-5 ${color}-ts ${color}-border`}>About Me</h2>
        <span className={`${color}-bar-1 mt-5 mb-3`}>'</span>
        <Row className="py-2 xsm-block">
          <Col
            className="mb-3"
            sm="12"
            md="12"
            lg="6"
            xl="6"
            data-aos="fade-up"
          >
            <Image
              fluid
              src={color === "orange" ? orange_coder : purple_coder}
              alt="web developer"
            />
          </Col>
          <Col className="my-auto" data-aos="fade-up">
            <h5 className="mb-0 px-4 black-ts">
              I'm a{" "}
              <span className={`fs-2 ${color}-font-color`}>front-end</span> web
              developer based in{" "}
              <span className={`fs-2 ${color}-font-color`}>Canada</span>. I'm{" "}
              <span className={`fs-2 ${color}-font-color`}>passionate </span>
              about what I do. I always{" "}
              <span className={`fs-2 ${color}-font-color`}>strive</span> to{" "}
              <span className={`fs-2 ${color}-font-color`}>exceed</span>{" "}
              expectations and
              <span className={`fs-2 ${color}-font-color`}> deliver</span> what
              is <span className={`fs-2 ${color}-font-color`}>promised</span>.
            </h5>
          </Col>
        </Row>
        <Row className="py-2 xsm-none" data-aos="fade-up">
          <Col className="my-auto">
            <h4 className="mb-0 px-3 pt-5 px-xl-5 black-ts">
              I'm a{" "}
              <span className={`fs-2 ${color}-font-color`}>front-end</span> web
              developer based in{" "}
              <span className={`fs-2 ${color}-font-color`}>Canada</span>. I'm{" "}
              <span className={`fs-2 ${color}-font-color`}>passionate </span>
              about what I do. I always{" "}
              <span className={`fs-2 ${color}-font-color`}>strive</span> to{" "}
              <span className={`fs-2 ${color}-font-color`}>exceed</span>{" "}
              expectations and
              <span className={`fs-2 ${color}-font-color`}> deliver</span> what
              is <span className={`fs-2 ${color}-font-color`}>promised</span>.
            </h4>
          </Col>
          <Col sm="12" md="12" lg="6" xl="6">
            <Image
              fluid
              src={color === "orange" ? orange_coder : purple_coder}
              alt="web developer"
            />
          </Col>
        </Row>
        <span className={`${color}-bar-1 mt-4 mt-lg-5 mt-xl-5 mb-5`} data-aos="fade-up">
          '
        </span>
        <h2 className={`${color}-ts ${color}-border`} data-aos="fade-up">
          My Tools
        </h2>
        <span className={`${color}-bar-1 my-5`} data-aos="fade-up">
          '
        </span>
        <div className="xsm-none">
          <div
            className="d-flex justify-content-center mt-5"
            data-aos="fade-up"
          >
            {logoStack.map((x) => {
              return (
                <LazyLoadImage
                  key={Math.random()}
                  width="75"
                  height="75"
                  className={`mx-3 rounded-circle ${color}-skills-logo`}
                  src={x}
                  alt="logo"
                />
              );
            })}
          </div>
        </div>
        <div className="xsm-block">
          <div
            className="d-flex justify-content-center mt-5"
            data-aos="fade-up"
          >
            {logoStack.map((x) => {
              return (
                <LazyLoadImage
                  key={Math.random()}
                  width="50"
                  height="50"
                  className={`mx-3 rounded-circle ${color}-skills-logo`}
                  src={x}
                  alt="logo"
                />
              );
            })}
          </div>
        </div>
        <span className={`${color}-bar-1 my-5`} data-aos="fade-up">
          '
        </span>
        <h2 className={`${color}-ts ${color}-border`} data-aos="fade-up">
          My Services
        </h2>
        <span className={`${color}-bar-1 my-5`} data-aos="fade-up">
          '
        </span>
        <CardGroup
          className="xsm-none"
          data-aos="flip-right"
          data-aos-duration="3000"
        >
          <Card className={`${color} mx-3`}>
            <LazyLoadImage
              variant="top"
              src={color === "orange" ? orange_design : purple_design}
            />
            <Card.Header style={{ fontSize: 25 }} className="p-4 black-ts">
              Website Development
            </Card.Header>
            <Card.Body>
              <Card.Text style={{ fontSize: 18 }} className="gemunu black-ts">
                I'll develop and deploy a customized website that meets your
                needs.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className={`${color} mx-3`}>
            <LazyLoadImage
              variant="top"
              src={color === "orange" ? orange_features : purple_features}
            />
            <Card.Header style={{ fontSize: 25 }} className="p-4 black-ts">
              Feature Development
            </Card.Header>
            <Card.Body>
              <Card.Text style={{ fontSize: 18 }} className="gemunu black-ts">
                I'll develop and implement website features based on your
                requirements.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className={`${color} mx-3`}>
            <LazyLoadImage
              variant="top"
              src={color === "orange" ? orange_bugs : purple_bugs}
            />
            <Card.Header style={{ fontSize: 25 }} className="p-4 black-ts">
              Code Review
            </Card.Header>
            <Card.Body>
              <Card.Text style={{ fontSize: 18 }} className="gemunu black-ts">
                I'll review your code to troubleshoot bugs and provide
                suggestions.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>

        <Carousel
          className="xsm-block px-2"
          interval={null}
          fade
          indicators={false}
          data-aos="flip-right"
          data-aos-duration="3000"
        >
          <Carousel.Item className="h-100">
            <Card className={`${color} m-2`}>
              <Image
                className="card-img-top"
                variant="top"
                src={color === "orange" ? orange_design : purple_design}
                alt="website design"
              />
              <Card.Header className="p-4">
                <div className="d-flex justify-content-between">
                  <ArrowLeftCircle className="my-auto" size={20} />
                  <p className="mb-0" style={{ fontSize: 25 }}>
                    Website <br /> Development
                  </p>
                  <ArrowRightCircle className="my-auto" size={20} />
                </div>
              </Card.Header>
              <Card.Body>
                <Card.Text className="gemunu black-ts">
                  I'll develop and deploy a customized website that meets your
                  needs.
                </Card.Text>
              </Card.Body>
            </Card>
          </Carousel.Item>
          <Carousel.Item className="h-100">
            <Card className={`${color} m-2`}>
              <Image
                className="card-img-top"
                variant="top"
                src={color === "orange" ? orange_features : purple_features}
                alt="website features"
              />
              <Card.Header className="p-4">
                <div className="d-flex justify-content-between">
                  <ArrowLeftCircle className="my-auto" size={20} />
                  <p className="mb-0" style={{ fontSize: 25 }}>
                    Feature <br /> Development
                  </p>
                  <ArrowRightCircle className="my-auto" size={20} />
                </div>
              </Card.Header>
              <Card.Body>
                <Card.Text className="gemunu black-ts">
                  I'll develop and implement website features based on your
                  requirements.
                </Card.Text>
              </Card.Body>
            </Card>
          </Carousel.Item>

          <Carousel.Item className="h-100">
            <Card className={`${color} m-2`}>
              <Image
                className="card-img-top"
                variant="top"
                src={color === "orange" ? orange_bugs : purple_bugs}
                alt="fixing bugs"
              />
              <Card.Header className="p-4">
                <div className="d-flex justify-content-between">
                  <ArrowLeftCircle className="my-auto" size={20} />
                  <p className="mb-0" style={{ fontSize: 25 }}>
                    Code <br /> Review
                  </p>
                  <ArrowRightCircle className="my-auto" size={20} />
                </div>
              </Card.Header>
              <Card.Body>
                <Card.Text className="gemunu black-ts">
                  I'll review your code to troubleshoot bugs and provide
                  suggestions.
                </Card.Text>
              </Card.Body>
            </Card>
          </Carousel.Item>
        </Carousel>
        <span className={`${color}-bar-1 my-5`} data-aos="fade-up">
          '
        </span>
        <h2 className={`${color}-ts ${color}-border`} data-aos="fade-up">
          Contact Me
        </h2>
        <span className={`${color}-bar-1 mt-5`} data-aos="fade-up">
          '
        </span>
        <Row className="py-2 mb-5 mb-lg-0 mb-xl-0">
          <Col sm="12" md="12" lg="6" xl="6" data-aos="fade-up">
            <Image
              fluid
              src={color === "orange" ? orange_contact : purple_contact}
              alt="contact info"
            />
          </Col>
          <Col className="my-auto" data-aos="fade-up">
            <h5 className="mb-0 px-3 px-xl-5">via email: </h5>
            <span className={`fs-3 ${color}-font-color gemunu black-ts`}>
              coder3103@gmail.com
            </span>
            <div className="d-flex justify-content-center">
              <div>
                <h5 className="mt-3 mb-0 px-3 px-xl-5">via contact form: </h5>
                <ContactForm />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
